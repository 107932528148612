import * as React from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import Title from "../components/Title";
import Content from "../components/Content";
import Image from "../components/Image";
import SEO from "../components/SEO";

export const query = graphql`
  query AboutQuery {
    sanitySiteSettings {
      summary
      hours {
        _key
        _type
        style
        children {
          _key
          _type
          text
        }
      }
      contact {
        _key
        _type
        style
        children {
          _key
          _type
          text
        }
      }
      locationImage1 {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      locationImage2 {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;

// markup
function About({ data }) {
  const {
    summary,
    hours,
    contact,
    locationImage1,
    locationImage2,
  } = data.sanitySiteSettings;
  return (
    <>
      <SEO
        title="CLIPS Hair Studio | We are a full-service beauty salon!"
        description="CLIPS Hair Studio is a hair salon in West Little Rock offering Color, Keratin and more.  Book your appointment today, and let's make you look fantastic!"
        url="https://www.clips-hairstudio.com/about/"
      />

      <Content>
        <Title>About</Title>
        <Image
          className="odd"
          fluid={locationImage1.asset.fluid}
          alt="salon stations"
        />
        <p className="content">{summary}</p>
        <Image
          className="even"
          fluid={locationImage2.asset.fluid}
          alt="salon stations"
        />
        <div className="left">
          <BlockContent blocks={hours} />
          <BlockContent blocks={contact} />
        </div>
      </Content>
    </>
  );
}

export default About;
